import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    aliasesContent:
      'Maak via een alias een extra e-mailadres. Je kunt via dit adres e-mails versturen en ontvangen vanuit de inbox van het hoofdaccount. De alias heeft geen eigen inbox. Je kunt maximaal 10 aliassen maken.',
    aliasLabel: 'Nieuwe alias',
    add: 'Nu maken',
    addressInUseErrorText: 'Sorry, deze alias bestaat al. Maak een andere alias of voeg een aantal cijfers toe.',
    successText: 'Gelukt! Je alias is klaar.',
    errorText: 'Dat lukte niet. De alias is niet gemaakt. Probeer je het nog eens?',
    emailAddressInUse:
      'Dit e-mailadres bestaat al. Verzin je iets nieuws? Je kunt ook cijfers of speciale tekens toevoegen.',
    delete: 'Alias verwijderen',

    confirmModal: {
      header: 'Weet je het zeker?',
      content: `
          <p>
            Je kunt de alias daarna niet meer gebruiken.
          </p>
      `,
      contentRed: `
          <p>
            <strong>#{alias} definitief verwijderen?</strong>
          </p>
      `,
      confirmButton: 'Ja, verwijderen',
      cancelButton: 'Toch maar niet',
    },

    removalFailedModal: {
      header: 'Niet gelukt...',
      content: `
          <p>
          Het lukt niet om dit alias te verwijderen, sorry. Probeer het later nog eens.
          </p>
      `,
      button: 'Ok',
    },

    removalSuccessModal: {
      header: 'Gelukt!',
      content: `
          <p>
            Het alias <strong>#{alias}</strong> is verwijderd.
          </p>
      `,
      button: 'Naar E-mail instellen',
    },
  },
  en: {},
};
