import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { ChevronIcon, SearchClearIcon, SearchRefreshIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { appSettings } from 'config/app.settings';
import { useAISearchContext } from 'context/ai-search/ai-search.context';
import { useClickOutsideAndKeyNavigation } from 'hooks/use-click-outside-and-key-navigation.hook';
import React, { FormEvent, KeyboardEvent, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { ContentUtil } from 'utils/content';

import { content } from './ai-search.content';
import * as S from './ai-search.styles';

const contentUtil = new ContentUtil({ content, componentName: 'AISearch' });

interface IAISearchProps {
  toggleSearchBar: () => void;
}

interface IAISearchResultProps {
  markdown: string;
}

const AISearchResult = ({ markdown }: IAISearchResultProps) => {
  return <Markdown>{markdown}</Markdown>;
};

export const AISearch = ({ toggleSearchBar }: IAISearchProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);
  const { aiSearchResult, getAISearchResult, isLoading, hasError, resetError } = useAISearchContext();

  const ref = useClickOutsideAndKeyNavigation((_event) => {
    toggleSearchBar();
  });

  useEffect(() => {
    searchInput?.current?.focus();
  }, [searchInput]);

  useEffect(() => {
    if (hasError || (aiSearchResult === null && submitted)) {
      setSubmitted(false);
      window.location.href = `${appSettings.ENV_ZIGGO_URL}/zoeken/?q=${encodeURI(searchQuery)}`;
      resetError();
    }
  }, [hasError, aiSearchResult, submitted]);

  const onShowAllResults = () => {
    if (searchQuery) {
      window._ddm.trigger('fe.ga_event', {
        data: {
          type: 'user_event',
          interaction: {
            event: 'show_all_results',
            type: 'impression',
            description: 'customer clicked on Laad meer resultaten of AI Search block',
            text: 'laad meer resultaten',
            status: undefined,
            detail: undefined,
          },
          component: {
            technical_name: '<AISearch>',
            meaningful_name: 'ai_search_block',
            location: 'ai_search_navigator',
            id: 'ai-search-popup',
            type: 'popup',
          },
          action: 'search',
          searchQuery,
        },
      });
      window.location.href = `${appSettings.ENV_ZIGGO_URL}/zoeken/?q=${encodeURI(searchQuery)}`;
    }
  };

  const onSubmitTrigger = (eventSource: 'enter_key' | 'button_click') => {
    window._ddm.trigger('fe.ga_event', {
      data: {
        type: 'user_event',
        interaction: {
          event: `submit_ai_search_${eventSource}`,
          type: 'zoekresultaten',
          description:
            eventSource === 'button_click'
              ? 'customer clicked on the search button of AI Search block'
              : 'customer press enter in the search input of AI Search block',
          text: undefined,
          status: undefined,
          detail: undefined,
        },
        component: {
          technical_name: '<AISearch>',
          meaningful_name: 'ai_search_block',
          location: 'ai_search_navigator',
          id: 'ai-search-popup',
          type: 'popup',
        },
        action: 'search',
        searchQuery,
      },
    });
  };

  const submit = () => {
    if (!isLoading && !hasError && searchQuery) {
      getAISearchResult(searchQuery);
      setSubmitted(true);
    }
  };

  const onClickSubmit = (event: MouseEvent) => {
    event.preventDefault();

    submit();
    onSubmitTrigger('button_click');
  };

  const onEnterSubmit = (event: FormEvent) => {
    event.preventDefault();

    submit();
    onSubmitTrigger('enter_key');
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit();
      onSubmitTrigger('enter_key');
    }
  };

  const resetSearch = () => {
    setSearchQuery('');
    setSubmitted(false);
    searchInput?.current?.focus();
  };

  return (
    <S.Form data-testid="ai-search-popup" onSubmit={onEnterSubmit} ref={ref as RefObject<HTMLFormElement>}>
      <S.SearchContainer>
        <S.SearchInput hasResults={submitted && !hasError}>
          <S.Search
            ref={searchInput}
            maxLength={1024}
            data-testid="search-input"
            value={searchQuery}
            placeholder={contentUtil.translateToString('placeholder')}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={onKeyDown}
            onSubmit={onEnterSubmit}
          />
          {!isLoading && submitted && !hasError && (
            <S.ButtonIcon type="button" onClick={onClickSubmit}>
              <SearchRefreshIcon />
            </S.ButtonIcon>
          )}
          {searchQuery && (
            <S.ButtonIcon type="button" onClick={resetSearch}>
              <SearchClearIcon />
            </S.ButtonIcon>
          )}
          <S.SubmitButton type="submit" active={Boolean(searchQuery)} onClick={onClickSubmit}>
            <ChevronIcon />
          </S.SubmitButton>
        </S.SearchInput>
        {submitted && !hasError && (
          <S.SearchResult>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <AISearchResult markdown={aiSearchResult || ''} />
                <S.Disclaimer>{contentUtil.translateToString('disclaimer')}</S.Disclaimer>
                <S.SeparationLine />
                <Button
                  buttonVariant={ButtonVariant.LINK}
                  testid="show-all-results-button"
                  external
                  isLeftAligned
                  onClick={onShowAllResults}>
                  {contentUtil.translateToString('loadMoreResults')}
                </Button>
              </>
            )}
          </S.SearchResult>
        )}
      </S.SearchContainer>
    </S.Form>
  );
};
