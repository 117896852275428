import React, { SVGProps } from 'react';

export const SearchRefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="black"
      d="M10.1174 1.11679C5.77148 1.11679 2.23336 4.65379 2.23336 8.99967V9.19317L0.667356 7.62717C-0.221394 8.51592 -0.221394 9.92217 0.667356 10.812L3.35948 13.5042L6.05386 10.812C6.94261 9.92217 6.94261 8.51592 6.05386 7.62717L4.48673 9.19317V8.99967C4.48673 5.89579 7.01236 3.36904 10.1174 3.36904C13.2224 3.36904 15.748 5.89579 15.748 8.99967C15.748 12.1047 13.2224 14.6303 10.1174 14.6303C8.87423 14.6303 7.86511 15.6394 7.86511 16.8837H10.1174C14.4632 16.8837 18.0002 13.3467 18.0002 8.99967C18.0002 4.65379 14.4632 1.11679 10.1174 1.11679Z"
    />
  </svg>
);
