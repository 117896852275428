import styled, { css, rem, spacingBase, tablet } from 'theme';
import borderRadius from 'theme/global/border-radius';

export const Form = styled('form')`
  position: relative;
`;

export const SearchContainer = styled.div`
  position: absolute;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.grayD20};
  background-color: white;
  padding: ${() => spacingBase} 0;
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: calc(100% - ${rem(32)});
  height: ${rem(48)};
  margin: 0 auto;

  ${tablet(css`
    width: calc(100% - ${rem(48)});
    max-width: calc(${(p) => p.theme.grid.maxWidth} - ${rem(48)});
  `)}
`;

export const Search = styled('input')`
  padding-left: ${() => spacingBase};
  border: ${rem(1)} solid ${(p) => p.theme.colors.gray600};
  border-radius: ${borderRadius.input};
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  font-size: ${rem(14)};
`;

export const Button = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  right: ${() => spacingBase};
  transform: translateY(-50%);
  display: block;
  color: ${(p) => p.theme.colors.gray600};
  cursor: pointer;

  svg {
    width: ${rem(16)};
    height: ${rem(16)};
    fill: currentColor;
    vertical-align: bottom;
  }
`;
