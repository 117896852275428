import React, { SVGProps } from 'react';

export const IDIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_1670_1352)">
      <path
        d="M19.19 2H0V16.19C0 18.84 2.17 21 4.81 21H19.19C21.84 21 24 18.83 24 16.19V6.81C24 4.16 21.83 2 19.19 2Z"
        fill="black"
      />
      <path
        d="M10.72 12.05H3.96997V11.36C3.96997 10.44 4.71997 9.69 5.63997 9.69H9.03997C9.95997 9.69 10.71 10.44 10.71 11.36V12.05H10.72ZM7.34997 5.13C6.32997 5.13 5.48997 5.96 5.48997 6.99C5.48997 8.02 6.31997 8.85 7.34997 8.85C8.37997 8.85 9.20997 8.02 9.20997 6.99C9.20997 5.96 8.37997 5.13 7.34997 5.13Z"
        fill="white"
      />
      <path d="M4.35999 14.38H19.45" stroke="white" strokeWidth="1.5" strokeMiterlimit="5.33" strokeLinecap="round" />
      <path d="M4.35999 17.12H13.74" stroke="white" strokeWidth="1.5" strokeMiterlimit="5.33" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_1670_1352">
        <rect width="24" height="19" fill="white" transform="translate(0 2)" />
      </clipPath>
    </defs>
  </svg>
);
