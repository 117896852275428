import React, { SVGProps } from 'react';

export const InternetSpeedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="white" />
    <path
      d="M14 18.4884C14 19.7284 13 20.7384 11.76 20.7484C10.52 20.7484 9.51 19.7484 9.5 18.5084C9.5 17.2684 10.5 16.2584 11.74 16.2484C12.98 16.2484 13.98 17.2484 13.99 18.4884H14Z"
      fill="black"
    />
    <path
      d="M20.55 7.61847C15.52 2.70847 7.47 2.80847 2.56 7.83847C2.56 7.83847 2.56 7.83847 2.55 7.84847L1 9.51847L2.32 10.7585C2.89 11.2685 3.75 11.2685 4.32 10.7585L4.42 10.6585C7.99 6.66847 14.12 6.32847 18.12 9.89847C18.29 10.0485 18.45 10.1985 18.6 10.3585L21.37 8.44847L20.55 7.61847Z"
      fill="black"
    />
    <path
      d="M11.59 10.0885C9.45998 10.1285 7.42998 11.0485 5.99998 12.6285C5.69998 12.9685 5.53998 13.3985 5.53998 13.8485C5.53998 14.2985 5.69998 14.7285 5.99998 15.0585C6.58998 15.6885 7.56998 15.7285 8.19998 15.1485C8.22998 15.1185 8.25998 15.0885 8.28998 15.0585C9.60998 13.6585 11.66 13.2185 13.44 13.9485L16.49 11.8385C15.11 10.6985 13.38 10.0785 11.59 10.0885Z"
      fill="black"
    />
    <path
      d="M22.8695 9.57846C23.0295 9.44846 23.0495 9.20846 22.9195 9.05846C22.7895 8.90846 22.5495 8.87846 22.3995 9.00846L13.0095 15.4885C14.0095 15.8685 14.7595 16.7185 15.0095 17.7585L22.8795 9.57846H22.8695Z"
      fill="black"
    />
  </svg>
);
