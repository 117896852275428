import { SearchIcon } from 'assets/icons';
import { appSettings } from 'config/app.settings';
import { useClickOutsideAndKeyNavigation } from 'hooks/use-click-outside-and-key-navigation.hook';
import React, { FormEvent, KeyboardEvent, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';

import * as S from './search.styles';

interface ISearchProps {
  toggleSearchBar: () => void;
}

export const Search = ({ toggleSearchBar }: ISearchProps) => {
  const [value, setValue] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);

  const ref = useClickOutsideAndKeyNavigation(() => {
    toggleSearchBar();
  });

  useEffect(() => {
    inputEl?.current?.focus();
  }, [inputEl]);

  const onSubmitTrigger = (eventSource: 'enter_key' | 'button_click') => {
    window._ddm.trigger('fe.ga_event', {
      data: {
        type: 'user_event',
        interaction: {
          event: `submit_search_${eventSource}`,
          type: 'impression',
          description:
            eventSource === 'button_click'
              ? 'customer clicked on the search button Search block'
              : 'customer press enter in the search input of Search block',
          text: 'zoekresultaten',
          status: undefined,
          detail: undefined,
        },
        component: {
          technical_name: '<Search>',
          meaningful_name: 'search_block',
          location: 'search_navigator',
          id: 'search-popup',
          type: 'popup',
        },
        action: 'search',
        searchQuery: value,
      },
    });
  };

  const onSubmit = () => {
    if (value) {
      window.location.href = `${appSettings.ENV_ZIGGO_URL}/zoeken/?q=${encodeURI(value)}`;
    }
  };

  const onClickSubmit = (event: MouseEvent) => {
    event.preventDefault();

    onSubmit();
    onSubmitTrigger('button_click');
  };

  const onEnterSubmit = (event: FormEvent) => {
    event.preventDefault();

    onSubmit();
    onSubmitTrigger('enter_key');
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
      onSubmitTrigger('enter_key');
    }
  };

  return (
    <S.Form data-testid="search-popup" onSubmit={onEnterSubmit} ref={ref as RefObject<HTMLFormElement>}>
      <S.SearchContainer>
        <S.SearchWrapper>
          <S.Search
            ref={inputEl}
            data-testid="search-input"
            onKeyDown={onKeyDown}
            onChange={(e) => setValue(e.target.value)}
          />
          <S.Button type="submit" onClick={onClickSubmit}>
            <SearchIcon />
          </S.Button>
        </S.SearchWrapper>
      </S.SearchContainer>
    </S.Form>
  );
};
