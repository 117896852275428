import React, { SVGProps } from 'react';

export const MovingVanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2857 16.2857C22.2857 16.9954 21.7106 17.5714 21 17.5714C20.2894 17.5714 19.7143 16.9954 19.7143 16.2857C19.7143 15.5751 20.2894 15 21 15C21.7106 15 22.2857 15.5751 22.2857 16.2857ZM10.2857 16.2857C10.2857 16.9954 9.71057 17.5714 9 17.5714C8.28943 17.5714 7.71429 16.9954 7.71429 16.2857C7.71429 15.5751 8.28943 15 9 15C9.71057 15 10.2857 15.5751 10.2857 16.2857ZM15.4285 14.5714C15.4285 15.2323 15.6865 15.8297 16.0971 16.2857H11.1428C11.1428 15.1037 10.1811 14.1429 8.99997 14.1429C7.81882 14.1429 6.85711 15.1037 6.85711 16.2857C5.90997 16.2857 5.14282 15.5177 5.14282 14.5714V6H18.8571C20.2774 6 21.4285 7.15114 21.4285 8.57143H16.2857H15.4285V9.42857V14.5714ZM22.2857 12.8571H19.7143V10.2857H21.4286C21.9017 10.2857 22.2857 10.6689 22.2857 11.1429V12.8571ZM18.8571 12.8571C18.384 12.8571 18 12.4731 18 12V10.2857H18.8571V12.8571ZM23.7497 13.3697L23.1429 13.0911V11.1429C23.1429 10.1966 22.3749 9.42857 21.4286 9.42857H16.2857V14.5714C16.2857 15.5177 17.0529 16.2857 18 16.2857H18.8571C18.8571 15.1037 19.8189 14.1429 21 14.1429C22.1811 14.1429 23.1429 15.1037 23.1429 16.2857H23.5714C23.808 16.2857 24 16.0929 24 15.8571V13.7597C24 13.5909 23.9023 13.44 23.7497 13.3697Z"
      fill="#1C1D1E"
    />
    <path
      d="M0 8.57143H6.85714C7.33053 8.57143 7.71429 8.95518 7.71429 9.42857H0.857144C0.383757 9.42857 0 9.04481 0 8.57143Z"
      fill="white"
    />
    <path
      d="M0 9.42857H6.85714C7.33053 9.42857 7.71429 9.81233 7.71429 10.2857H0.857144C0.383757 10.2857 0 9.90196 0 9.42857Z"
      fill="#1C1D1E"
    />
    <path
      d="M2.57141 10.2857H9.42855C9.90194 10.2857 10.2857 10.6695 10.2857 11.1429H3.42855C2.95517 11.1429 2.57141 10.7591 2.57141 10.2857Z"
      fill="white"
    />
    <path
      d="M2.57141 11.1429H9.42855C9.90194 11.1429 10.2857 11.5266 10.2857 12H3.42855C2.95517 12 2.57141 11.6162 2.57141 11.1429Z"
      fill="#1C1D1E"
    />
    <path
      d="M0.857178 12H7.71432C8.18771 12 8.57146 12.3838 8.57146 12.8571H1.71432C1.24093 12.8571 0.857178 12.4734 0.857178 12Z"
      fill="white"
    />
    <path
      d="M0.857178 12.8571H7.71432C8.18771 12.8571 8.57146 13.2409 8.57146 13.7143H1.71432C1.24093 13.7143 0.857178 13.3305 0.857178 12.8571Z"
      fill="#1C1D1E"
    />
  </svg>
);
