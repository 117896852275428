import React from 'react';

export const WrenchIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.609 9.46278C29.533 9.00678 28.967 8.83278 28.641 9.15878L25.125 12.6728C24.865 12.9328 24.441 12.9328 24.181 12.6728L20.207 8.69878C19.945 8.43678 19.945 8.01478 20.207 7.75478L23.723 4.23878C24.047 3.91478 23.873 3.34478 23.419 3.27078C20.775 2.83678 17.941 3.81478 16.145 6.21678C14.659 8.19878 14.315 10.8048 15.073 13.0888L2.64502 25.5188C2.17302 25.9928 2.17302 26.7568 2.64502 27.2308C4.77702 29.3608 8.23302 29.3608 10.365 27.2308L19.793 17.8028C22.413 18.6848 25.421 18.0908 27.511 16.0048C29.291 14.2228 29.987 11.7708 29.609 9.46278Z"
      fill="#1C1D1E"
    />
  </svg>
);
